import * as React from 'react'
import loadable from '@loadable/component'

const AppointmentPicker = loadable(() =>
    import('/src/components/Complex/AppointmentPicker')
)
const TextField = loadable(() => import('/src/components/Form/TextField'))

const StepAppointmentPicker = ({
    leadId,
    collectionId,
    appointmentDuration,
    setSlotFieldValue
}) => {
    return (
        <>
            <AppointmentPicker
                weeks={4}
                type={'form'}
                leadId={leadId}
                collectionId={collectionId}
                appointmentDuration={appointmentDuration}
                setSlotFieldValue={setSlotFieldValue}
            />

            <TextField type={'hidden'} name={'ref'} required />

            <TextField type={'hidden'} name={'slot'} required />
        </>
    )
}

export default StepAppointmentPicker
